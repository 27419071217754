import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {SponsorService} from '../../../shared/services/sponsor.service';
import {isPlatformBrowser} from '@angular/common';
import {AuthenticationService} from '@isifid/core';

@Component({
    templateUrl: './session-has-expired.component.html',
    styleUrls: ['./session-has-expired.component.scss'],
    standalone: false
})
export class SessionHasExpiredComponent {
    loading: boolean;

    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly sponsorService: SponsorService,
        private readonly router: Router,
        @Inject(PLATFORM_ID) private platformId
    ) {
        this.loading = true;
        // Redirect if still auth
        if (authenticationService.isAuthenticated()) {
            // Check if a sponsor is defined
            if (sponsorService.consumer !== null) {
                // If so, check if the birthDate exists which is a pure sponsor attribute
                if (sponsorService.consumer.birthDate !== '') this.router.navigate(['/parrain/inviter']).then();
                else this.router.navigate(['/filleul/accueil']).then();
                // Redirect to home of sponsorship
            } else this.router.navigate(['/parrain/accueil']).then();
        }
        // Stop loading only if real user, keep it on SSR to avoid some lag effects if the user refreshes
        if (isPlatformBrowser(this.platformId)) this.loading = false;
    }
}
