import {Component} from '@angular/core';
import {SponsorService} from '../../../shared/services/sponsor.service';
import {environment} from '../../../../environments/environment';
import {SponsorshipService} from '../../../shared/services/sponsorship.service';
import {Router} from '@angular/router';
import {AuthenticationService, SponsorshipContent, SponsorshipUser, TrackingService} from '@isifid/core';
import {DomSanitizer, SafeHtml, SafeStyle} from '@angular/platform-browser';
import {TrackingServiceSponsorship} from '../../../shared/services/tracking-service-sponsorship.service';

@Component({
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: false
})

export class HomeComponent {
    cdnUrl = environment.cdnUrl;
    buttonCopyContent = 'Copier';
    legalNoticeSpecificStep3: SafeHtml;
    shareUrl: string;
    slides: any;
    spanCopy: string;
    sponsorshipUser: SponsorshipUser;
    content: SponsorshipContent;

    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly router: Router,
        private readonly sanitizer: DomSanitizer,
        public readonly sponsorshipService: SponsorshipService,
        private readonly sponsorService: SponsorService,
        private readonly trackingService: TrackingService,
        private readonly trackingServiceSponsorship: TrackingServiceSponsorship
    ) {
        // Log out if sponsor code for another client
        if (environment.production && window.location.origin.indexOf(this.sponsorshipService.getSettings().domain) === -1) {
            this.authenticationService.logout(false, false);
            this.router.navigate(['/logout']).then();
        }

        this.sponsorshipUser = this.sponsorService.sponsorshipUser;
        this.content = this.sponsorshipService.content;
        this.legalNoticeSpecificStep3 = this.sanitizer.bypassSecurityTrustHtml(this.sponsorshipService.getContent().legalNoticeSpecificStep3);

        this.spanCopy = '';
        this.shareUrl = `${window.location.origin}/c/${this.sponsorshipUser.codeCustomised || this.sponsorshipUser.code}`;
        const sponsorCode = `<span class="bold">${this.sponsorshipUser.codeCustomised || this.sponsorshipUser.code}</span>`;
        this.content.sponsorCode1 = this.content.sponsorCode1.replace('${sponsorCode}', sponsorCode);
        this.sponsorshipService.content.sponsoredSponsorshipText = this.sponsorshipService.content.sponsoredSponsorshipText
            .replace('${sponsorFirstname}', this.sponsorService.consumer.firstName)
            .replace('${sponsorLastname}', this.sponsorService.consumer.lastName);
        this.setSlides();
    }

    goToAppointment(): void {
        const url = `${this.sponsorshipService.getSettings().appointmentUrl}`;
        // Check if the URL internal or external
        // Open a new tab if external
        if (url && url.indexOf(window.location.origin) === -1) {
            this.trackingService.trackEvent('appointment', 'client website', '', '', this.trackingServiceSponsorship.getClientTrackerId()).subscribe();
            window.open(url, '_blank');
        } else {
            this.trackingService.trackEvent('appointment', 'isifid website', '', '', this.trackingServiceSponsorship.getClientTrackerId()).subscribe();
            this.router.navigate(['/filleul/rendez-vous']).then();
        }
    }

    backgroundImgNoSponsoredReward(): SafeStyle {
        return this.sanitizer.bypassSecurityTrustStyle(
            `url('${environment.cdnUrl}/sponsorship/img/sponsoredHome.jpg')`);
    }

    private setSlides() {
        this.slides = [];
        this.slides.push(this.cdnUrl + '/sponsorship/img/carousel/carousel-1.jpg');
        this.slides.push(this.cdnUrl + '/sponsorship/img/carousel/carousel-2.jpg');
        this.slides.push(this.cdnUrl + '/sponsorship/img/carousel/carousel-3.jpg');
        this.slides.push(this.cdnUrl + '/sponsorship/img/carousel/carousel-4.jpg');
        this.slides.push(this.cdnUrl + '/sponsorship/img/carousel/carousel-5.jpg');
        this.slides.push(this.cdnUrl + '/sponsorship/img/carousel/carousel-6.jpg');
        this.slides.push(this.cdnUrl + '/sponsorship/img/carousel/carousel-7.jpg');
    }
}
