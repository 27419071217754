import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SponsorshipService} from '../../../../shared/services/sponsorship.service';
import {SponsorService} from '../../../../shared/services/sponsor.service';
import {CustomValidator} from '../../../../shared/helpers/custom.validator';
import {Consumer, FormValidators, MobileService, MsConsumersService, MsServicesSponsorshipService} from '@isifid/core';
import {finalize, forkJoin, of} from 'rxjs';

@Component({
    selector: 'app-sponsor-information',
    templateUrl: './informations.component.html',
    styleUrls: ['./informations.component.scss'],
    standalone: false
})
export class InformationsComponent {
    button: string;
    loading: boolean = true;
    minDate: Date;
    maxDate: Date;
    allowCommunications: boolean;
    sponsorForm: FormGroup;
    private mobile: string;
    private readonly consumer: Consumer;

    constructor(
        public readonly sponsorService: SponsorService,
        public readonly sponsorshipService: SponsorshipService,
        private readonly mobileService: MobileService,
        private readonly formBuilder: FormBuilder,
        private readonly formValidators: FormValidators,
        private readonly msConsumersService: MsConsumersService,
        private readonly msServicesSponsorshipService: MsServicesSponsorshipService
    ) {
        this.button = 'save';
        this.allowCommunications = !!this.sponsorService.sponsorshipUser?.allowCommunications;
        this.consumer = this.sponsorService.consumer;
        this.minDate = this.sponsorshipService.getMinDate();
        this.maxDate = this.sponsorshipService.getMaxDate();
        // Get consumer with consumerId for phoneNumber
        this.msConsumersService.getConsumer(this.consumer.id).subscribe(consumer => {
            this.mobile = consumer.mobile;
            this.sponsorForm = this.formBuilder.group({
                firstName: [this.consumer.firstName, [Validators.required, CustomValidator.firstNameLastName]],
                lastName: [this.consumer.lastName, [Validators.required, CustomValidator.firstNameLastName]],
                mobile: [this.mobile, [Validators.required, this.formValidators.isFrenchMobilePhone]],
                email: [this.consumer.email, [Validators.required, Validators.email]]
            });
            this.loading = false;
        });
    }

    submit(): void {
        if (this.sponsorForm.invalid) {
            this.showErrors();
            return;
        }

        this.loading = true;
        const consumerData = {};
        for (const property in this.sponsorForm.getRawValue()) {
            if (property === 'mobile') {
                if (this.sponsorForm.get(property).value && this.sponsorForm.get(property).value !== this.consumer) {
                    consumerData[property] = this.mobileService.formatMobile(this.sponsorForm.get(property).value);
                }
            } else if (this.sponsorForm.get(property).value !== this.consumer[property]) consumerData[property] = this.sponsorForm.get(property).value;
        }

        const observers = [
            this.sponsorService.updateConsumer(consumerData),
            this.sponsorService.sponsorshipUser?.id ?
                this.msServicesSponsorshipService.updateSponsorshipUser(
                    this.sponsorService.sponsorshipUser.id, {allowCommunications: this.allowCommunications}
                ) :
                of(null)
        ];
        forkJoin(observers)
            .pipe(finalize(() => this.loading = false))
            .subscribe({
                next: () => {
                    this.sponsorService.initByConsumerId(this.consumer.id.toString()).subscribe();
                    this.button = 'Mis à jour !';
                }
            });
    }

    private showErrors() {
        for (const control in this.sponsorForm.controls) {
            if (this.sponsorForm.get(control).invalid) this.sponsorForm.get(control).markAsDirty();
        }
        // Got focus to the error field
        const invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
        invalidFields[0].focus();
    }
}
