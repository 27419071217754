import {Component, OnInit} from '@angular/core';
import {SponsorshipService} from '../../../../shared/services/sponsorship.service';
import {SponsorService} from '../../../../shared/services/sponsor.service';
import {MsServicesSponsorshipService, Sponsored} from '@isifid/core';

@Component({
    selector: 'app-sponsor-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss'],
    standalone: false
})
export class HistoryComponent implements OnInit {
    invitations: Array<Sponsored> = [];
    rewards: Array<any> = [];

    constructor(
        public readonly sponsorshipService: SponsorshipService,
        private readonly msServicesSponsorshipService: MsServicesSponsorshipService,
        private readonly sponsorService: SponsorService
    ) {
    }

    ngOnInit(): void {
        this.msServicesSponsorshipService.getSponsorshipUserBySponsorConsumerId(this.sponsorService.consumer.id)
            .subscribe({
                next: (sponsored) => {
                    this.rewards = [];
                    sponsored.forEach((s) => {
                        this.rewards.push(s);
                    });
                },
                error: () => console.error('error while getting sponsorshipUser by code'),
                complete: () => {
                    this.msServicesSponsorshipService.getInvitations().subscribe({
                        next: (invitations) => {
                            this.invitations = [];
                            // Remove duplicate
                            this.setInvitations(invitations);
                        }
                    });
                }
            });
    }

    reSendInvite(invitation): void {
        this.msServicesSponsorshipService.resendInvites(invitation.id).subscribe();
    }

    private setInvitations (invitations) {
        invitations.forEach((invite) => {
            if (!this.invitations.find(i => i.email === invite.email) && !this.rewards.find(i => i.email === invite.email) ||
              !this.invitations.find(i => i.mobile === invite.mobile) && !this.rewards.find(i => i.mobile === invite.mobile)
            ) {
                this.invitations.push(invite);
            }
        });
    }
}
