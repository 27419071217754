import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {SponsorService} from '../../../../shared/services/sponsor.service';
import {SponsorshipService} from '../../../../shared/services/sponsorship.service';
import {SponsorshipSettings} from '@isifid/core';

@Component({
    templateUrl: './CEHDF0059038FD37B4A.component.html',
    styleUrls: ['./CEHDF0059038FD37B4A.component.scss'],
    standalone: false
})
export class CEHDF0059038FD37B4AComponent {
    private settings: SponsorshipSettings;

    constructor(
        private readonly router: Router,
        private readonly sponsorService: SponsorService,
        private readonly sponsorshipService: SponsorshipService
    ) {
        this.settings = sponsorshipService.getSettings();
    }

    referralCreation(): void {
        if (this.sponsorService.sponsorshipUser) this.router.navigate(['/parrain/inviter']).then();
        else this.router.navigate(['/parrain/compte/creation']).then();
    }
}
