import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '@isifid/core';

@Component({
    selector: 'app-logout',
    template: '',
    standalone: false
})
export class LogoutComponent {
    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly router: Router
    ) {
        if (this.authenticationService.isAuth.value) this.authenticationService.logout(false, false);
        this.router.navigateByUrl('/session-has-expired').then();
    }
}
