import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SponsorshipService} from '../../../../shared/services/sponsorship.service';
import {CustomValidator} from '../../../../shared/helpers/custom.validator';
import {SponsorService} from '../../../../shared/services/sponsor.service';
import {environment} from '../../../../../environments/environment';
import {
    FormValidators,
    MsServicesSponsorshipService,
    SponsorshipContent,
    SponsorshipSettings, SponsorshipUser
} from '@isifid/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
    selector: 'app-make',
    templateUrl: './make.component.html',
    styleUrls: ['./make.component.scss'],
    standalone: false
})
export class MakeComponent implements OnInit {
    appointmentForm: FormGroup;
    content: SponsorshipContent;
    cdnUrl = environment.cdnUrl;
    legalNoticeSpecificStep3: SafeHtml;
    loading = true;
    settings: SponsorshipSettings;
    sponsorshipUser: SponsorshipUser;
    timeSlots: Array<string> = [
        'de 9h à 12h',
        'de 14h à 16h',
        'de 16h à 18h'
    ];
    agencySelected = {
        nom: '',
        contact: {emailAddress: ''}
    };

    constructor(
        private readonly sanitizer: DomSanitizer,
        private readonly formBuilder: FormBuilder,
        private readonly formValidators: FormValidators,
        private readonly msServicesSponsorshipService: MsServicesSponsorshipService,
        private readonly router: Router,
        private readonly sponsorshipService: SponsorshipService,
        private readonly sponsorService: SponsorService
    ) {
        this.content = this.sponsorshipService.getContent();
        this.settings = this.sponsorshipService.getSettings();
        this.sponsorshipUser = this.sponsorService.sponsorshipUser;
        this.legalNoticeSpecificStep3 = this.sanitizer.bypassSecurityTrustHtml(this.content.legalNoticeSpecificStep3);
    }

    ngOnInit(): void {
        if (this.settings.templateHash === 'BPAURA331E956C2034677') {
            this.timeSlots = [
                'de 8h à 12h',
                'de 12h à 14h',
                'de 14h à 17h',
                'de 17h à 19h',
                'de 14h à 18h',
                'de 18h à 20h'
            ];
        } else if (this.settings.templateHash === 'CEMPAB93BCA26396A02') {
            this.timeSlots = [
                'de 9h à 12h',
                'de 12h à 14h',
                'de 14h à 16h',
                'de 16h à 18h',
                'de 18h à 20h'
            ];
        }
        this.appointmentForm = this.formBuilder.group({
            gender: ['', Validators.required],
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            mobile: ['', [Validators.required, this.formValidators.isFrenchMobilePhone]],
            birthDate: ['', Validators.required],
            preferredAgency: [''],
            sponsorAgency: [''],
            preferredTime: '',
            sponsorCode: [this.sponsorshipUser.codeCustomised || this.sponsorshipUser.code, Validators.required]
        });
        if (this.settings.appointmentEmailMethod === 'bank_branch') {
            this.appointmentForm.get('preferredAgency').setValidators(CustomValidator.requiredAgency);
        }
        this.loading = false;
    }

    submit(): void {
        if (this.appointmentForm.invalid) {
            this.showErrors();
            return;
        }
        if (typeof this.appointmentForm.get('birthDate').value !== 'string') {
            this.appointmentForm.value.birthDate = this.appointmentForm.value.birthDate.format('YYYY-MM-DD');
        }
        if (this.agencySelected.nom && this.agencySelected.contact.emailAddress) {
            this.appointmentForm.value.preferredAgency = this.agencySelected.nom + ' <' + this.agencySelected.contact.emailAddress.replace('-at-', '@') + '>';
        }
        this.msServicesSponsorshipService.createAppointmentRequests(this.settings.id, this.appointmentForm.value)
            .subscribe({
                next: () => {
                  this.router.navigate(['/filleul/rendez-vous/confirmation']).then();
                },
                error: () => {
                  this.router.navigate(['/error']);
                }
            });
    }


    private showErrors() {
        for (const control in this.appointmentForm.controls) {
            if (this.appointmentForm.get(control).invalid) {
                this.appointmentForm.get(control).markAsDirty();
            }
        }
    }
}
